import React from 'react'

type ShelfProps = {
  children: React.ReactNode,
  isFilledRow?: boolean,
}

function ShelfRow(props: ShelfProps) {
  const justifyStyle = props.isFilledRow ? 'justify-space-between' : 'justify-space-around' ;
  return (
    <div className={"flex flex-row shelf-row-separator shelf-row-back relative"} style={{minHeight: '200px'}}>
      <div className={`flex flex-row ${justifyStyle}`} style={{paddingLeft: '35px', paddingRight: '35px', position: 'absolute', bottom: '10px', width: '100%'}}>
        {props.children}
      </div>
      <div className={"shelf-row-side"} style={{left: 0}}></div>
      <div className={"shelf-row-side"} style={{right: 0}}></div>
      <div className={"shelf-row-base"}></div>
      <div className={"shelf-corner"}></div>
      <div className={"shelf-corner"} style={{right: 0, rotate: "180deg", left:'unset'}}></div>
    </div>
  )
}

export default ShelfRow
