import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from './elements/Footer'
import Banner from './elements/Banner'

function Layout() {
  return (
    <>
      <Banner />
      <Outlet />
      <Footer />
    </>
  );
}

export default Layout
