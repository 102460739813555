import React from 'react';
import {IconProps} from '../Icon'

function GoIcon(props: IconProps) {
  return(
    <svg width="800px" height="800px" viewBox="0 0 48 48">
      <g id="Layer_2" data-name="Layer 2">
        <g id="invisible_box" data-name="invisible box">
          <rect width="48" height="48" fill={props.color}/>
        </g>
        <g id="Q3_icons" data-name="Q3 icons">
          <g>
            <path d="M44.8,18.1l-.5-.5c-1-1-2-1.9-2.9-2.9l-2.9-2.9L35.7,9,32.8,6.1,29.9,3.2a.8.8,0,0,0-.7-.2L24.8,4.2,15.2,6.8,8.9,8.5c-.3,0-.4.1-.5.4l-1.8,7c-.5,1.6-.9,3.2-1.3,4.7S3.8,26.3,3,29.1a1,1,0,0,0,.3,1l2.9,2.8,5.4,5.5c2.2,2.1,4.3,4.2,6.4,6.4a.8.8,0,0,0,.7.2l6.7-1.8,7.1-2,6.4-1.6a.8.8,0,0,0,.7-.7l1.2-4.7c.8-3.2,1.7-6.3,2.6-9.5l1.2-4.9a5.7,5.7,0,0,0,.4-1.4ZM33.2,34.6a13.9,13.9,0,0,1-16.9,1A13.5,13.5,0,0,1,10.6,21a5.9,5.9,0,0,0,5.2,6.5,6.3,6.3,0,0,0,6.7-4.1,6,6,0,0,0-2.9-7,6.3,6.3,0,0,0-8.1,2c0-.7,1.7-3.4,4.1-5.3a13.7,13.7,0,0,1,20.5,4.3,6.3,6.3,0,0,0-9-.8,5.6,5.6,0,0,0-1.4,7,6.1,6.1,0,0,0,10.4.8A5.8,5.8,0,0,0,37.2,20,13.8,13.8,0,0,1,33.2,34.6Zm-22-13a5.3,5.3,0,0,1,5.5-5.2,5.3,5.3,0,0,1,5.4,5.2,5.5,5.5,0,0,1-10.9,0Zm25.4-.7a5.3,5.3,0,0,1-5.5,5.3A5.4,5.4,0,0,1,25.7,21a5.5,5.5,0,0,1,10.9-.1Z"/>
            <path d="M27.2,26.7a.4.4,0,0,1-.4-.3,1.1,1.1,0,0,0-.7-1,3.8,3.8,0,0,0-3.7-.2,1.4,1.4,0,0,0-1,1.2c0,.3-.1.3-.3.4A2.8,2.8,0,0,0,19.5,28a1.8,1.8,0,0,0-.2,2.1A1.6,1.6,0,0,0,21,31h.5c0,.6-.1,1.3-.1,1.9s.5,1.8,1.4,1.7a8,8,0,0,0,1.5-.3c.1-.1.1-.1.1,0a2.3,2.3,0,0,0,2.1.2,1.7,1.7,0,0,0,.7-1.7c0-.7-.1-1.3-.1-2h.4a1.6,1.6,0,0,0,1.6-2A2.7,2.7,0,0,0,27.2,26.7Zm-3.3,6.8c0,.3-.3.6-.8.6s-1-.2-1-.6a20.9,20.9,0,0,1,0-2.5h.1l1.8-.8C24,31.4,24,32.4,23.9,33.5Zm2.2.5a2.2,2.2,0,0,1-1.1-.1c-.1,0-.3-.3-.3-.4V30.3l1.6.6a.2.2,0,0,1,.2.2,14.7,14.7,0,0,1,0,2.1A1,1,0,0,1,26.1,34Zm2.3-4.3c-.3.5-.8.5-1.5.6l-1.4-.4a3.2,3.2,0,0,0-2.7,0l-1.8.4a.9.9,0,0,1-1.1-.5,1.4,1.4,0,0,1,.1-1.5l.6-.6a1.2,1.2,0,0,1,1.4-.1,3.9,3.9,0,0,0,4.5-.2H27a3.9,3.9,0,0,1,1.1.6A1.7,1.7,0,0,1,28.4,29.7Z"/>
            <path d="M28.1,19.2a2,2,0,0,0-2,2.1,1.9,1.9,0,0,0,2,1.9,2,2,0,0,0,0-4Zm.9,3.1a.6.6,0,0,1-.6-.6.5.5,0,0,1,.6-.6.6.6,0,0,1,.6.5A.7.7,0,0,1,29,22.3Z"/>
            <path d="M15.6,21.9a1.9,1.9,0,0,0-2-2,2,2,0,0,0-2,2,2,2,0,0,0,2.1,2A1.9,1.9,0,0,0,15.6,21.9Zm-1-.1a.6.6,0,0,1,.5.6c0,.3-.4.6-.7.6a.6.6,0,0,1-.5-.7C14,21.9,14.2,21.8,14.6,21.8Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default GoIcon;
