import React from 'react';
import FlexContainer from './FlexContainer/FlexContainer'
import Icon from './Icon'
import {COLORS, styles} from './../../styles'

type CertificationItemProps = {
  name: string,
  issuer: string,
  date: string,
  icon: React.FunctionComponent
}

function CertificationItem(props: CertificationItemProps) {
  return(
    <FlexContainer paddingBottom='40px'>
      <Icon icon={props.icon} width={'90px'} height={'90px'} color={COLORS.b2} marginRight={'25px'} />
      <FlexContainer direction={'column'} justify={'center'} maxWidth={styles.container.maxWidth} flex={'1 1 auto'}>
        <p className={'mb-md font-family-secondary'} style={{...styles.p2, fontSize: styles.fontSize.l}}>{props.name}</p>
        <p style={styles.p1}>{props.issuer}</p>
        <p style={{...styles.label, fontSize: styles.fontSize.sm}}>Issued {props.date}</p>
      </FlexContainer>  
    </FlexContainer>
  )
}

export default CertificationItem;
