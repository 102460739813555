import React, {useState} from 'react';

function Banner() {
  return (
    <div style={{backgroundColor:'#fff8c5', paddingTop: '5px', paddingBottom: '5px', textAlign:'center', color:'#202328'}}>
        🇺🇦 Support Ukrainian army on <a href={"https://savelife.in.ua/en/"} target={"_blank"}> savelife.in.ua</a>. Support the light!
    </div>
  )
}

export default Banner;
