import React from 'react'

export type BookItem = {
  name: string,
  author: string,
  url: string,
  cover: string
}

function BookItem(props: BookItem) {
  return (
    <a href={props.url} target={"_blank"}>
      <div style={{zIndex: "3", height: '165px'}} className={"relative "}>
        <img className={"image-thumbnail"} src={props.cover} />
      </div>
    </a>
  )
}

export default BookItem
