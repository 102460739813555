import React from 'react';
import useHoverState from '../../hooks/useHoverState'

const DEFAULT_ICON_SIZE = '20px';

export type IconProps = {
  isHovered?: boolean,
  color?: string
}

type BaseIconProps = {
  icon: React.FunctionComponent<IconProps>,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
  marginRight?: string,
  width?: string,
  height?: string,
  color?: string
}

function Icon(props: BaseIconProps) {
  const {isHovered, handlers} = useHoverState();
  const RenderedIcon: React.FunctionComponent<IconProps> = props.icon;
  return (
    <div
      onClick={props.onClick ? props.onClick : () => {}}
      className={'icon-container'}
      {...handlers}
      style={{
        width: props.width ? props.width : DEFAULT_ICON_SIZE, height: props.height ? props.height : DEFAULT_ICON_SIZE,
        marginRight: props.marginRight ? props.marginRight : '0'
    }}>
      <RenderedIcon isHovered={isHovered} color={props.color} />
    </div>
  )
}

export default Icon;
