import React, {useEffect, useState} from 'react'
import './Bookshelf.css'
import FlexContainer from '../../elements/FlexContainer/FlexContainer'
import {styles} from '../../../styles'
import ShelfRow from './components/ShelfRow'
import BookItem from './components/BookItem'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

// const books = [
//   //did not read
//   "https://www.amazon.de/-/en/Cal-Newport/dp/1455586692",
//   "https://www.amazon.de/-/en/Timothy-Ferriss/dp/0307465357",
//   "https://www.amazon.de/-/en/Sparc-FLOW-ebook/dp/B071RSW5M4",
//   "https://github.com/G33kzD3n/Catalogue/blob/master/Cracking_the_Coding_Interview_189_Programming_Questions_and_Solutions.pdf",
//
//   //?
//   "https://www.amazon.de/-/en/dp/B0821QG5YV/ref=sr_1_1?keywords=easy+way+to+quit+smoking&qid=1686812881&s=audible&sprefix=easy+way+to+%2Caudible%2C84&sr=1-1",
//   "https://www.amazon.de/-/en/John-Gray-ebook/dp/B006UN3K4O/ref=sr_1_1?crid=2PF1PEYACI4KP&keywords=men+are+from+mars+women+are+from+venus&qid=1686813237&sprefix=women+from+ve%2Caps%2C110&sr=8-1",
//    "https://www.amazon.de/-/en/Lynn-Beighley/dp/0596526849/ref=sr_1_1?crid=3OVJ3SVHPRCJG&keywords=sql+brain&qid=1686813734&sprefix=sql+brain%2Caps%2C96&sr=8-1",
//   //literature
//   "https://www.amazon.de/-/en/Victor-Hugo/dp/1846140498/ref=sr_1_2?crid=1X8Q95NIFHQIL&keywords=les+miserables+victor+hugo+english&qid=1686812752&sprefix=victor+hugo+les+miserables+english%2Caps%2C94&sr=8-2",
//   "https://www.amazon.de/-/en/dp/B0BK5F48KW/ref=sr_1_2?crid=20LQ5DL5ZJ3M9&keywords=the+man+who+victor+hugo+english&qid=1686812821&sprefix=the+man+who+victor+hugo+english%2Caps%2C100&sr=8-2",
//   "https://www.amazon.de/-/en/Honor%C3%A9-Balzac/dp/B0BZ2NJG9P/ref=sr_1_6?crid=28MF14F8OEMGJ&keywords=de+balzac+the+deserted+woman&qid=1686813481&sprefix=de+balzac+the+deserted+woman%2Caps%2C89&sr=8-6",
//   "https://www.amazon.de/-/en/Edibooks/dp/1532803389/ref=sr_1_1?crid=17JQB552GYDOA&keywords=de+balzac+gobsec&qid=1686813539&sprefix=de+balzac+gobsec%2Caps%2C89&sr=8-1",
//
//   //ru
//   "https://www.amazon.de/-/en/Leo-Tolstoy/dp/0199232768/ref=sr_1_2?crid=38ETAPK8O96AY&keywords=war+and+piece+english+tolstoi&qid=1686812972&s=audible&sprefix=war+and+piece+english+tolstoi%2Caudible%2C95&sr=1-2-catcorr",
//   "https://www.amazon.de/-/en/Brothers-Karamazov-Epilogue-Penguin-Classics/dp/0140449248/ref=pd_bxgy_sccl_1/262-2283275-6585133?pd_rd_w=k62xr&content-id=amzn1.sym.1fd66f59-86e9-493d-ae93-3b66d16d3ee0&pf_rd_p=1fd66f59-86e9-493d-ae93-3b66d16d3ee0&pf_rd_r=WRXVPD68BWCDEC0MS0ST&pd_rd_wg=F4kjo&pd_rd_r=ae7c886a-c26c-4c64-9c68-3a810dac6229&pd_rd_i=0140449248&psc=1",
//   "https://www.amazon.de/-/en/Fyodor-Dostoyevsky/dp/0140449132/ref=pd_bxgy_sccl_1/262-2283275-6585133?pd_rd_w=Q6sWn&content-id=amzn1.sym.1fd66f59-86e9-493d-ae93-3b66d16d3ee0&pf_rd_p=1fd66f59-86e9-493d-ae93-3b66d16d3ee0&pf_rd_r=0BA8N2A0PBP6MSP535F1&pd_rd_wg=IGqun&pd_rd_r=b488dd4f-ae12-44cc-aa63-d2193d0d8f38&pd_rd_i=0140449132&psc=1",
//   "https://www.amazon.de/-/en/Fyodor-Dostoyevsky/dp/014044792X/ref=pd_bxgy_sccl_2/262-2283275-6585133?pd_rd_w=Q6sWn&content-id=amzn1.sym.1fd66f59-86e9-493d-ae93-3b66d16d3ee0&pf_rd_p=1fd66f59-86e9-493d-ae93-3b66d16d3ee0&pf_rd_r=0BA8N2A0PBP6MSP535F1&pd_rd_wg=IGqun&pd_rd_r=b488dd4f-ae12-44cc-aa63-d2193d0d8f38&pd_rd_i=014044792X&psc=1",
//   "https://www.amazon.de/-/en/Fyodor-Dostoyevsky/dp/0140455124/ref=d_zg-te-pba_sccl_2_3/262-2283275-6585133?pd_rd_w=92eGa&content-id=amzn1.sym.2773ee31-e1d7-4b66-89a3-82d27d16ebdc&pf_rd_p=2773ee31-e1d7-4b66-89a3-82d27d16ebdc&pf_rd_r=FVCTFPNESKPG9E74TGJR&pd_rd_wg=guQIE&pd_rd_r=16fd5156-15d5-41d6-bf85-f619ff0dc49c&pd_rd_i=0140455124&psc=1",
// ]

const techBooks = [
  {
    name: "Designing Data-Intensive Applications",
    author: "Martin Kleppmann",
    url: "https://www.amazon.com/-/en/Martin-Kleppmann/dp/1449373321/ref=sr_1_1?crid=3MXYR137114UW&amp;keywords=Designing+Data-Intensive+Applications&amp;qid=1686811541&amp;sprefix=designing+data-intensive+applications%252Caps%252C103&amp;sr=8-1&_encoding=UTF8&tag=petrom-20&linkCode=ur2&linkId=67147932f403366bad8cc0989536a59f&camp=1789&creative=9325",
    cover: "https://m.media-amazon.com/images/I/51ZSpMl1-LL._SX379_BO1,204,203,200_.jpg",
  },
  {
    name: "Clean Code",
    author: "Robert Martin",
    url: "https://www.amazon.com/-/en/Robert-Martin/dp/0132350882/ref=sr_1_1?crid=LYZ1O8NWXX8J&amp;keywords=clean+code&amp;qid=1686812486&amp;sprefix=cleancode%252Caps%252C103&amp;sr=8-1&_encoding=UTF8&tag=petrom-20&linkCode=ur2&linkId=371f732aa1173040caa2070fed800efc&camp=1789&creative=9325",
    cover: "https://m.media-amazon.com/images/I/41xShlnTZTL._SX376_BO1,204,203,200_.jpg",
  },
  {
    name: "Atomic Habits",
    author: "James Clear",
    url: "https://www.amazon.com/Atomic-Habits-Proven-Build-Break/dp/1847941842/ref=tmm_pap_swatch_0?_encoding=UTF8&amp;qid=1687098526&amp;sr=8-1&_encoding=UTF8&tag=petrom-20&linkCode=ur2&linkId=ad8576616f65253a9a677ca87ec2390f&camp=1789&creative=9325",
    cover: "https://m.media-amazon.com/images/I/51B7kuFwQFL._SX329_BO1,204,203,200_.jpg",
  },
  {
    name: "Linux Basics for Hackers",
    author: "OccupyTheWeb",
    url: "https://www.amazon.com/-/en/OccupyTheWeb/dp/1593278551/ref=sr_1_1?keywords=linux+for+hackers&amp;qid=1686813621&amp;sprefix=linux+for+%252Caps%252C104&amp;sr=8-1&_encoding=UTF8&tag=petrom-20&linkCode=ur2&linkId=a023ae314d5c66674df57c9d7bf93ad3&camp=1789&creative=9325",
    cover: "https://m.media-amazon.com/images/I/51fBisfHhDL._SX376_BO1,204,203,200_.jpg",
  },
  {
    name: "Introduction to Algorithms",
    author: "Thomas H. Cormen, Charles E. Leiserson, Ronald L. Rivest, Clifford Stein",
    url: "https://www.amazon.com/-/en/Thomas-H-Cormen/dp/026204630X/ref=sr_1_3?crid=1Q33F01OQZ01O&amp;keywords=algorithms&amp;qid=1686812577&amp;sprefix=algo%252Caps%252C117&amp;sr=8-3&_encoding=UTF8&tag=petrom-20&linkCode=ur2&linkId=e9d47a617b31efa8efd15f8b0c2c4fcd&camp=1789&creative=9325",
    cover: "https://m.media-amazon.com/images/I/41vLer1KbmL._SX437_BO1,204,203,200_.jpg"
  },
  {
    name: "The Subtle Art of Not Giving a F*ck",
    author: "Mark Manson",
    url: "https://www.amazon.com/-/en/Mark-Manson/dp/0062641549/ref=sr_1_4?keywords=how+to+not+give+a+fck&amp;qid=1686812650&amp;sprefix=how+to+not+g%252Caps%252C104&amp;sr=8-4&_encoding=UTF8&tag=petrom-20&linkCode=ur2&linkId=32d4b43718b5237b199a81c187fda8ea&camp=1789&creative=9325",
    cover: "https://m.media-amazon.com/images/I/511vJPN7p5L._SX331_BO1,204,203,200_.jpg",
  },
  {
    name: "Go Programming Language",
    author: "Alan A. A. Donovan",
    url: "https://www.amazon.com/-/en/Alan-Donovan/dp/0134190440/ref=sr_1_1?keywords=golang+book&amp;qid=1686813858&amp;sprefix=golang+%252Caps%252C99&amp;sr=8-1&_encoding=UTF8&tag=petrom-20&linkCode=ur2&linkId=83586c69740769812223c64437d39859&camp=1789&creative=9325",
    cover: "https://m.media-amazon.com/images/I/41+02Q6ir0L._SX402_BO1,204,203,200_.jpg",
  },
  // {
  //   name: "Rich Dad Poor Dad",
  //   author: "Robert T. Kiyosaki",
  //   url: "https://www.amazon.com/-/en/Robert-T-Kiyosaki/dp/1612681131/ref=sr_1_1?keywords=rich+dad+poor+dad&amp;qid=1686812551&amp;sprefix=ric%252Caps%252C112&amp;sr=8-1&_encoding=UTF8&tag=petrom-20&linkCode=ur2&linkId=1e275e04a48ef1fd7c3a74a5a024c4dd&camp=1789&creative=9325",
  //   cover: "https://m.media-amazon.com/images/I/51Hfv2MfNGL._SX331_BO1,204,203,200_.jpg",
  // },
  {
    name: "System Design Interview",
    author: "Alex Xu",
    url: "https://www.amazon.de/-/en/Alex-Xu/dp/B08CMF2CQF",
    cover: "https://m.media-amazon.com/images/I/51dicdpovNL._SL1500_.jpg",
  },
  {
    name: "12 Rules for Life",
    author: "Jordan B. Peterson",
    url: "https://www.amazon.com/12-Rules-Life-Antidote-Chaos/dp/0241351634/ref=tmm_hrd_swatch_0?_encoding=UTF8&amp;qid=1686813149&amp;sr=8-1&_encoding=UTF8&tag=petrom-20&linkCode=ur2&linkId=a857c2b7128c1060c16a080ddd58f951&camp=1789&creative=9325",
    cover: "https://m.media-amazon.com/images/I/41PaA+n6+cL._SX324_BO1,204,203,200_.jpg",
  },
  {
    name: "Grokking the System Design Interview",
    author: "Design Gurus",
    url: "https://www.amazon.de/Grokking-System-Design-Interview-interview/dp/B09NRJT1NF/ref=cm_cr_arp_d_product_top?ie=UTF8",
    cover: "https://m.media-amazon.com/images/I/61+vFldY+8L._SL1303_.jpg",
  },
  // {
  //   name: "Network Security Through Data Analysis",
  //   author: "Michael Collins",
  //   url: "https://www.amazon.com/Network-Security-Through-Data-Analysis/dp/1491962844/?&_encoding=UTF8&tag=petrom-20&linkCode=ur2&linkId=2e9daacf8875325b3032085f20e8735a&camp=1789&creative=9325",
  //   cover: "https://m.media-amazon.com/images/I/51b4hBb2vLL._SX379_BO1,204,203,200_.jpg",
  // },
  // {
  //   name: "Head First SQL",
  //   author: "Lynn Beighley",
  //   url: "https://www.amazon.com/-/en/Lynn-Beighley/dp/0596526849/ref=sr_1_1?crid=3OVJ3SVHPRCJG&amp;keywords=sql+brain&amp;qid=1686813734&amp;sprefix=sql+brain%252Caps%252C96&amp;sr=8-1&_encoding=UTF8&tag=petrom-20&linkCode=ur2&linkId=f4cd1dde8985bbfb17e0616258eac6f1&camp=1789&creative=9325",
  //   cover: "https://m.media-amazon.com/images/I/513K5FRtC0L._SX430_BO1,204,203,200_.jpg",
  // },
]

const lScreenBooksToRender: { [key: number]: number[] } = {
  0: [0, 1, 2, 3, 4],
  1: [5, 6, 7, 8, 9],
}
const mScreenBooksToRender: { [key: number]: number[] } = {
  0: [0, 1, 2, 3],
  1: [4, 5, 6, 7],
  2: [8, 9]
}
const sScreenBooksToRender: { [key: number]: number[] } = {
  0: [0, 1, 2],
  1: [3, 4, 5],
  2: [6, 7, 8],
  3: [9]
}
const smScreenBooksToRender: { [key: number]: number[] } = {
  0: [0, 1],
  1: [2, 3],
  2: [4,5],
  3: [6,7],
  4: [8,9]
}

function objectLength(obj: { [key: number|string]: any }) {
  if (!obj) {
    return 0;
  }
  return Object.keys(obj).length;
}

function Bookshelf() {
  const {width} = useWindowDimensions();
  const [booksToRender, setBooksToRender] = useState<{[key: number]: number[]}>(() => lScreenBooksToRender);
  useEffect(() => {
    const currentList = objectLength(booksToRender);
    if (width > 800) {
      if (currentList !== objectLength(lScreenBooksToRender)) {
        setBooksToRender(lScreenBooksToRender);
      }
    } else if (width > 620) {
      if (currentList !== objectLength(mScreenBooksToRender)) {
        setBooksToRender(mScreenBooksToRender);
      }
    } else if (width > 450) {
      if (currentList !== objectLength(sScreenBooksToRender)) {
        setBooksToRender(sScreenBooksToRender);
      }
    } else if (currentList !== objectLength(smScreenBooksToRender)) {
      setBooksToRender(smScreenBooksToRender);
    }
  }, [width])

  return (
    <FlexContainer justify={'center'} align={'center'} flex={'1 1 auto'}>
      <FlexContainer maxWidth={'970px'} direction={'column'} flex={'1 1 auto'} justify={'center'}>
        <h2 style={{
          color: styles.background.dark.backgroundColor,
          marginTop: '40px',
          marginBottom: '40px',
          textAlign:'center',
          fontWeight: 700,
          fontSize: '40px',
          lineHeight: '35px'
        }}>
          Personal Must-Reads
        </h2>
        <div className={"shelf-container cursor-pointer"}>
          {/*@ts-ignore*/}
          {Object.keys(booksToRender).map((rowID: number, key: number) => {
            return (
              <ShelfRow key={`row${rowID}${key}`} isFilledRow={booksToRender[rowID].length === booksToRender[0].length}>
                {
                  booksToRender[rowID].map((bookID: number, bookKey: number) => {
                    return <BookItem key={`book${rowID}${bookID}${bookKey}`} {...techBooks[bookID]} />
                  })
                }
              </ShelfRow>
            )
          })}
        </div>

      </FlexContainer>
    </FlexContainer>
  )
}

export default Bookshelf;
