import React from 'react';
import FlexContainer from '../../elements/FlexContainer/FlexContainer'
import {COLORS, styles} from '../../../styles'
import './TechStack.css'
import Skill from '../../elements/Skill/Skill'
import GithubIcon from '../../elements/assets/GithubIcon'
import DjangoIcon from '../../elements/assets/DjangoIcon'
import GoIcon from '../../elements/assets/GoIcon'
import DockerIcon from '../../elements/assets/DockerIcon'
import JavascriptIcon from '../../elements/assets/JavascriptIcon'
import JQueryIcon from '../../elements/assets/JQueryIcon'
import MySQLIcon from '../../elements/assets/MySSQLIcon'
import NodeIcon from '../../elements/assets/NodeIcon'
import ReactIcon from '../../elements/assets/ReactIcon'
import TypescriptIcon from '../../elements/assets/TypescriptIcon'
import WebSecurityIcon from '../../elements/assets/WebSecurityIcon'
import WebIcon from '../../elements/assets/WebIcon'

function TechStack() {
  return (
    <FlexContainer justify={'center'} align={'center'} flex={'1 1 auto'}>
      <FlexContainer maxWidth={'970px'} direction={'column'} flex={'1 1 auto'} justify={'center'}>
        <h2 style={{
          color: styles.background.dark.backgroundColor,
          marginTop: '40px',
          marginBottom: '40px',
          textAlign:'center',
          fontWeight: 700,
          fontSize: '40px',
        }}>
          Technology Stack
        </h2>
        <div className={'skill-matrix'} style={{backgroundColor: COLORS.b2, marginBottom: '40px',}}>
          <Skill name={'JavaScript'} icon={JavascriptIcon} />
          <Skill name={'React'} icon={ReactIcon} />
          <Skill name={'JQuery'} icon={JQueryIcon} />
          <Skill name={'HTML/CSS'} icon={WebIcon} />
          <Skill name={'TypeScript'} icon={TypescriptIcon} />
          <Skill name={'NodeJS'} icon={NodeIcon} />
          <Skill name={'MySQL'} icon={MySQLIcon} />
          <Skill name={'GoLang'} icon={GoIcon} />
          <Skill name={'Django'} icon={DjangoIcon} />
          <Skill name={'Docker'} icon={DockerIcon} />
          <Skill name={'GitHub'} icon={GithubIcon} />
          <Skill name={'Security'} icon={WebSecurityIcon} />
        </div>
      </FlexContainer>
    </FlexContainer>
  )
}

export default TechStack;
