const COLORS = {
  b1: '#0b1a2f',
  b2: '#45b39d',
  t1: '#ccd6f7',
  t2: '#8893af'
}

const styles = {
  p1: {
    color: COLORS.t1,
  },
  p2: {
    color: COLORS.b2,
  },
  p3: {
    color: COLORS.t2,
    fontSize: 15,
  },
  label: {
    color: COLORS.t2,
  },
  button: {
    primary: {
      color: COLORS.b2,
      border: `2px solid ${COLORS.b2}`,
      backgroundColor: COLORS.b1
    }
  },
  background: {
    dark: {
      backgroundColor: COLORS.b1,
    },
    light: {
      backgroundColor: COLORS.t1,
    }
  },
  container: {
    maxWidth: '1100px'
  },
  fontSize: {
    l: 28,
    md: 24,
    sm: 19
  }
}

export {
  styles,
  COLORS
}
