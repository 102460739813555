import React from 'react';
import FlexContainer from '../FlexContainer/FlexContainer'
import Icon from '../Icon'
import {COLORS, styles} from '../../../styles'

type SkillProps = {
  name: string,
  icon: React.FunctionComponent
}

function Skill(props: SkillProps) {
  return(
    <div style={{backgroundColor: styles.background.dark.backgroundColor, padding: '15px', borderRadius: '10px'}}>
      <FlexContainer align={'center'}>
        <Icon icon={props.icon} width={'30px'} height={'30px'} color={COLORS.b2} marginRight={'25px'} />
        <p className='font-family-secondary' style={{...styles.p2, lineHeight: '30px', color: '#ccd6f7'}}>{props.name}</p>
      </FlexContainer>
    </div>
  )
}

export default Skill;
