import React from 'react';
import {IconProps} from '../Icon'

function WebSecurityIcon(props: IconProps) {
  return(
    <svg fill={props.color} version="1.1" width="800px" height="800px" viewBox="0 0 399.997 399.997">
      <g>
        <g>
          <g>
            <path d="M366.684,24.971H33.316C14.945,24.971,0,39.917,0,58.288V275.56c0,18.371,14.945,33.317,33.316,33.317h107.412
				l-20.387,44.955c-2.105,4.643-1.709,10.037,1.053,14.321c2.762,4.285,7.51,6.873,12.607,6.873h131.996c0,0,0.014,0,0.02,0
				c8.285,0,15-6.715,15-15c0-2.493-0.605-4.848-1.686-6.916l-20.062-44.233h107.412c18.371,0,33.316-14.946,33.316-33.317V58.288
				C400,39.917,385.055,24.971,366.684,24.971z M370,275.561c0,1.829-1.488,3.316-3.316,3.316H33.316
				c-1.828,0-3.316-1.487-3.316-3.316V58.288c0-1.829,1.488-3.317,3.316-3.317h333.367c1.828,0,3.316,1.488,3.316,3.317L370,275.561
				L370,275.561z"/>
            <path d="M270.717,102.373c-0.875-0.532-21.94-13.063-70.717-13.063c-48.774,0-69.844,12.531-70.716,13.063
				c-2.779,1.697-4.415,4.771-4.276,8.022c0.041,0.958,1.11,23.836,10.078,52.987c5.285,17.18,12.238,33.006,20.669,47.054
				c10.647,17.738,23.686,32.667,38.757,44.371c1.613,1.255,3.549,1.883,5.488,1.883c1.939,0,3.875-0.628,5.488-1.883
				c15.069-11.704,28.108-26.633,38.756-44.371c8.43-14.048,15.385-29.874,20.67-47.054c8.969-29.151,10.038-52.029,10.078-52.987
				C275.131,107.144,273.494,104.07,270.717,102.373z M233.23,145.784l-39.563,39.557c-1.143,1.152-2.665,1.781-4.279,1.781
				c-1.619,0-3.141-0.629-4.291-1.781l-18.328-18.363c-2.359-2.362-2.359-6.2,0-8.563l3.529-3.53
				c1.148-1.136,2.671-1.765,4.287-1.765c1.616,0,3.131,0.623,4.271,1.765l10.528,10.493l31.72-31.719
				c1.146-1.149,2.663-1.781,4.284-1.781c1.618,0,3.14,0.632,4.277,1.781l3.563,3.561C235.59,139.581,235.59,143.422,233.23,145.784
				z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default WebSecurityIcon;
