import {useState} from 'react';

function useHoverState() {
  const [isHovered, setIsHover] = useState<boolean>(false);
  const onMouseOver = () => {
    if (isHovered) {
      return;
    }
    setIsHover(true);
  }

  const onMouseLeave = () => {
    if (!isHovered) {
      return;
    }
    setIsHover(false);
  }

  return {
    isHovered,
    handlers: {
      onMouseOver,
      onMouseLeave
    }
  }
}

export default useHoverState;
