import React, {MouseEventHandler} from 'react'
import {styles} from '../../../styles'
import './Button.css';

type ButtonProps = {
  children: React.ReactNode,
  onClick: MouseEventHandler<HTMLButtonElement>,
}

function Button(props: ButtonProps) {
  return (
    <button
      onClick={props.onClick}
      className='button-primary font-family-secondary'
      style={styles.button.primary}>
      {props.children}
    </button>
  )
}

export default Button
