import React from 'react';
import { Routes, Route } from "react-router-dom";
import './Reset.css';
import './App.css';
import Layout from './components/Layout'
import Home from './pages/Home'
import Page404 from './pages/Page404'

function App() {
  return (
    <div className="container-fluid">
      <Routes>
        <Route  path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
