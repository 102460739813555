import React from 'react';
import {IconProps} from '../Icon'

function ITILIcon(props: IconProps) {
  return(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="225.000000pt" height="225.000000pt" viewBox="0 0 225.000000 225.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
   fill="#0057b7" stroke="none">
   <path d="M1010 2240 c-146 -16 -262 -51 -400 -121 -460 -236 -698 -763 -573
   -1267 47 -190 161 -389 305 -528 286 -278 680 -383 1068 -284 525 134 887 654
   831 1191 -27 255 -131 484 -304 666 -159 167 -365 281 -592 327 -93 19 -242
   26 -335 16z m161 -444 c92 -20 163 -58 229 -125 112 -112 147 -233 120 -419
   -18 -125 -3 -192 53 -246 l41 -39 -48 6 c-61 9 -114 38 -146 82 -14 19 -28 35
   -31 35 -3 0 -24 -14 -45 -31 -22 -18 -68 -46 -104 -63 -61 -29 -72 -31 -180
   -31 -111 0 -118 1 -185 34 -128 63 -214 179 -238 318 -40 244 173 483 438 492
   17 0 60 -6 96 -13z m-303 -1193 l-3 -158 -32 -3 -33 -3 0 160 0 161 35 0 36 0
   -3 -157z m267 127 c0 -22 -5 -26 -40 -30 l-40 -5 -3 -122 c-3 -122 -3 -123
   -28 -129 -14 -4 -29 -3 -34 2 -5 5 -11 63 -12 129 l-3 120 -37 3 c-35 3 -38 6
   -38 33 l0 30 118 -3 117 -3 0 -25z m115 -124 c0 -98 -4 -157 -11 -161 -5 -4
   -21 -4 -35 -1 l-24 6 0 155 0 155 35 0 35 0 0 -154z m128 27 l3 -123 43 0 c60
   0 76 -8 76 -36 0 -24 -1 -24 -100 -24 l-100 0 0 148 c0 82 3 152 7 156 4 4 21
   6 38 4 l30 -3 3 -122z"/>
   <path d="M982 1670 c-63 -28 -136 -109 -162 -177 -44 -118 -13 -259 77 -345
   42 -41 114 -77 153 -78 21 0 16 5 -27 30 -62 36 -115 88 -139 138 -22 45 -30
   171 -15 228 25 93 99 167 205 204 l51 19 -50 0 c-29 0 -68 -8 -93 -19z"/>
   <path d="M1156 1560 c-71 -22 -126 -82 -152 -167 -30 -96 14 -201 100 -242 54
   -26 77 -22 124 23 83 77 117 231 74 329 -28 63 -71 79 -146 57z"/>
   </g>
   </svg>
  )
}

export default ITILIcon;
