import React from 'react';
import FlexContainer from '../../elements/FlexContainer/FlexContainer'
import {COLORS, styles} from '../../../styles'
import Skill from '../../elements/Skill/Skill'
import GithubIcon from '../../elements/assets/GithubIcon'
import DjangoIcon from '../../elements/assets/DjangoIcon'
import GoIcon from '../../elements/assets/GoIcon'
import DockerIcon from '../../elements/assets/DockerIcon'
import JavascriptIcon from '../../elements/assets/JavascriptIcon'
import JQueryIcon from '../../elements/assets/JQueryIcon'
import MySQLIcon from '../../elements/assets/MySSQLIcon'
import NodeIcon from '../../elements/assets/NodeIcon'
import ReactIcon from '../../elements/assets/ReactIcon'
import TypescriptIcon from '../../elements/assets/TypescriptIcon'
import WebSecurityIcon from '../../elements/assets/WebSecurityIcon'
import WebIcon from '../../elements/assets/WebIcon'
import CertificationItem from '../../elements/CertificationItem';
import OSCPIcon from '../../elements/assets/OSCPIcon';
import ITILIcon from '../../elements/assets/ITILIcon';

function Certifications() {
  return (
    <FlexContainer justify={'center'} align={'center'} flex={'1 1 auto'}>
      <FlexContainer maxWidth={'970px'} direction={'column'} flex={'1 1 auto'} justify={'center'}>
        <h2 style={{
          color: styles.background.light.backgroundColor,
          marginTop: '40px',
          marginBottom: '40px',
          textAlign:'center',
          fontWeight: 700,
          fontSize: '40px',
        }}>
          Certifications
        </h2>
        <CertificationItem 
          name={"Offensive Security Certified Professional (OSCP)"} 
          issuer={"OffSec"}
          date={"Jul 2024"} 
          icon={OSCPIcon} />
        <CertificationItem 
          name={"ITIL® v4 Foundation Certificate in IT Service Management"} 
          issuer={"PeopleCert"}
          date={"Jan 2023"} 
          icon={ITILIcon} />
      </FlexContainer>
    </FlexContainer>
  )
}

export default Certifications;
